import React, { useEffect, useState } from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import Footer from "../components/footer";
import { useIntl, navigate } from "gatsby-plugin-intl";
import SEO from "../components/seo";
import { anuncioGet, anuncioContacto } from "../redux/anuncios/actions";
import { appRedirect } from "../redux/app/actions";
import { connect } from "react-redux";
import withPathPairs from "../hocs/withPathPairs";
import { appUIPopupTextoShow } from "../redux/app/actions";
import ReactLoading from "react-loading";
import Tools from "../classes/tools";

const AnuncioContacto = ({
	boot,
	authResolved,
	loggedIn,
	usuario,
	anuncio,
	anuncioGet,
	anuncioContacto,
	appRedirect,
	appUIPopupTextoShow,
	urlVars,
	anuncioContactoLoading,
	s,
}) => {
	const intl = useIntl();

	useEffect(() => {
		if (authResolved && !loggedIn) appRedirect({ to: "/" });
	}, [loggedIn, authResolved]);

	useEffect(() => {
		if (urlVars.id) anuncioGet(intl.locale, parseInt(urlVars.id));
		else appRedirect("/");
	}, []);

	const [fecha, setFecha] = useState("");
	const [comentarios, setComentarios] = useState("");
	const [acepto, setAcepto] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
		anuncioContacto(intl.locale, {
			token: usuario.token,
			id: anuncio.id,
			fecha,
			comentarios,
			acepto,
		});
	};

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	return (
		<>
			<BodyClassName className="msgrequest">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title="Envía una petición" />
					<main className="page form-page lightgray-bg-01">
						{anuncio && (
							<div className="steps-wrapper">
								{/* Bloque fijo debajo del menu nav header */}
								<article className="quick black-bg-02">
									<div className="wrapper box">
										{/* RENTER PROFILE FOR DESKTOP */}
										<div className="quick-description white-text hide-xs">
											<p className="description" dangerouslySetInnerHTML={{ __html: anuncio.title }} />
											{anuncio.usuario && (
												<small className="name">
													{anuncio.usuario.nombre} {anuncio.usuario.apellido}
												</small>
											)}
										</div>
										{/* /RENTER PROFILE FOR DESKTOP */}

										<div className="quick-info white-text">
											<span className="section-back hide-md"></span>
											<p className="board-type hide-xs">
												<span className="hide-xs">Tabla:</span>
												{anuncio.tabla ? anuncio.tabla.title : anuncio.tabla_otra}
											</p>
											<p className="price">
												{(anuncio.operacion === 1 || anuncio.operacion === 3) && (
													<div>
														<span className="hide-xs">Alquiler:</span>
														{anuncio.precio_alquiler}€ hora
													</div>
												)}
												{(anuncio.operacion === 2 || anuncio.operacion === 3) && (
													<div>
														<span className="hide-xs">Venta:</span>
														{anuncio.precio_venta}€
													</div>
												)}
											</p>
											<p className="location">
												<span className="hide-xs">Ubicación:</span>
												{anuncio.geo_descripcion}
											</p>
										</div>
										{anuncio.imagenes && anuncio.imagenes.length > 0 && (
											<div className="quick-img hide-md">
												<img className="profile-img" src={anuncio.imagenes[0].url} alt={anuncio.title} />
											</div>
										)}
										{!anuncio.imagenes ||
											(anuncio.imagenes.length === 0 && (
												<div className="quick-img hide-md">
													<img
														className="profile-img"
														src="/img/anuncio-sin-imagenes.png"
														alt={anuncio.title}
													/>
												</div>
											))}
									</div>
									{anuncio.imagenes && anuncio.imagenes.length > 0 && (
										<img className="board-img hide-xs" src={anuncio.imagenes[0].url} alt={anuncio.title} />
									)}
									{!anuncio.imagenes ||
										(anuncio.imagenes.length === 0 && (
											<img
												className="board-img hide-xs"
												src="/img/anuncio-sin-imagenes.png"
												alt={anuncio.title}
											/>
										))}
								</article>
								{/* /Bloque fijo */}

								<article className="form-box box">
									<h4 className="prequel-font black-text-01">{Tools.convertString(s.mensajepeticion?.title)}</h4>
									<form onSubmit={handleSubmit}>
										<fieldset>
											<label className="black-text-01" htmlFor="calendar">
												{anuncio.operacion === 1 && "¿Cuándo vas a alquilar la tabla?"}
												{anuncio.operacion === 2 && "¿Cuándo vas a comprar la tabla?"}
												{anuncio.operacion === 3 && "¿Cuándo vas a alquilar o comprar la tabla?"}
												<input
													className="lightgray-bg-01 black-text-03"
													placeholder="Escribe la fecha que te gustaría alquilar la tabla"
													value={fecha}
													name="calendar"
													id="calendar"
													onChange={(e) => setFecha(e.target.value)}
												/>
											</label>
											<label className="black-text-01" htmlFor="message">
												Cuéntale algo sobre ti
												<textarea
													className="textarea lightgray-bg-01 black-text-03"
													placeholder="Por qué alquilas, nivel de surf, cuántas horas quieres alquilar...."
													name="message"
													id="message"
													onChange={(e) => setComentarios(e.target.value)}
													value={comentarios}
												/>
											</label>

											<p className="accept">
												<input
													type="checkbox"
													id="accept"
													name="accept"
													checked={acepto}
													onChange={(e) => setAcepto(e.target.checked)}
												/>
												<span></span>
												Marca esta casilla si aceptas la
												<a
													className="black-text-01"
													onClick={() =>
														appUIPopupTextoShow({
															title: boot.terminos.title,
															body: boot.terminos.body,
															button: s.crearcuenta?.terminos_button,
														})
													}
												>
													política de SYB
												</a>
											</p>
										</fieldset>
										<div className="button-wrapper" style={{ marginTop: "15px" }}>
											<button
												type="submit"
												className="button black-bg-03 white-text"
												disabled={anuncioContactoLoading}
											>
												{!anuncioContactoLoading && "Enviar"}
												{anuncioContactoLoading && (
													<ReactLoading type="spin" color="white" width={16} height={16} />
												)}
											</button>
										</div>
									</form>
								</article>
							</div>
						)}
						<Footer />
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	const { boot } = state.app;
	const { resolved: authResolved, loggedIn, usuario } = state.auth;
	const { anuncio } = state.anuncios;
	const { anuncioContactoLoading } = state.app.events;
	return {
		boot,
		authResolved,
		loggedIn,
		usuario,
		anuncio,
		anuncioContactoLoading,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	anuncioGet,
	anuncioContacto,
	appRedirect,
	appUIPopupTextoShow,
};

export default connect(mapStateToProps, mapDispatchToProps)(withPathPairs(AnuncioContacto));
